(function($) {

	/* Magnific popup translations */

	$.extend(true, $.magnificPopup.defaults, {
		tClose: 'Sluiten (Esc)',
		tLoading: 'Bezig met laden...',
		// Gallery
		gallery: {
			tPrev: 'Vorige',
			tNext: 'Volgende',
			tCounter: '%curr% van %total%'
		},
		// Image
		image: {
			tError: '<a href="%url%">De afbeelding</a> kon niet worden geladen.'
		},
		ajax: {
			tError: '<a href="%url%">De inhoud</a> kon niet worden geladen.'
		}
	});

	
})(jQuery); // Fully reference jQuery after this point.