/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

 	slideshow = function() {
 		$('.slideshow').slick({
 			dots: false,
 			arrows: true,
 			lazyLoad: 'ondemand',
 			autoplaySpeed: 7000,
 			autoplay: true,
 			fade: true,
 			slidesToScroll: 1,
 			rows: 0,
 			slidesToShow: 1,
 			nextArrow: '<div class="slick-arrow slick-right"><i class="fa fa-chevron-right"></i></div>',
 			prevArrow: '<div class="slick-arrow slick-left"><i class="fa fa-chevron-left"></i></div>',
 			infinite: true,
 			mobileFirst: true,
 			adaptiveHeight: false,
 			cssEase: 'linear'
 		});
 	};

 	toggleDiscounts = function() {
 		$('.toggle-discounts').on('click', function(e) {
 			e.preventDefault();
 			$('.discounts').toggleClass('discounts-open');
 		});
 	};

 	blogSlider = function() {
 		if ($(window).width() < 768) {
 			$('.post--slider')
 			.slick({
 				dots: false,
 				customPaging : function(slider, i) {
 					return '<span></span>';
 				},
 				infinite: true,
 				slidesToShow: 1,
 				rows: 0,
 				arrows: true,
 				lazyLoad: 'ondemand',
 				autoplay: true,
 				autoplaySpeed: 3500,
 				fade: true,
 				nextArrow: '<div class="slick-arrow slick-right"><i class="fa fa-chevron-right"></i></div>',
	 			prevArrow: '<div class="slick-arrow slick-left"><i class="fa fa-chevron-left"></i></div>',
 				slidesToScroll: 1,
 				adaptiveHeight: false,
 				cssEase: 'linear'
 			})
 			.show();
 		} else {
 			if ($('.post--slider').hasClass('slick-initialized')) {
 				$('.post--slider').slick('unslick');
 			}
 		}
 	};

 	/*----------  On resize  ----------*/
 	var waitForFinalEvent = (function () {
 		var timers = {};
 		return function (callback, ms, uniqueId) {
 			if (!uniqueId) {
 				uniqueId = "Don't call this twice without a uniqueId";
 			}
 			if (timers[uniqueId]) {
 				clearTimeout (timers[uniqueId]);
 			}
 			timers[uniqueId] = setTimeout(callback, ms);
 		};
 	})();

 	$(window).resize(function() {
 		waitForFinalEvent(function(){
 			// s.refresh();
 			blogSlider();
 		}, 500);
 	});

 	/*----------  Product detailpage  ----------*/
 	productSlider = function() {
 		var numSlides = $('.productslider-nav .product__image').length;

 		if(numSlides <= 4) {
 			$('.productslider-nav').addClass('productslide');
 		}

 		$('.productslider-for').slick({
 			slidesToShow: 1,
 			slidesToScroll: 1,
 			adaptiveHeight: false,
 			infinite: true,
 			rows: 0,
 			fade: true,
 			arrows: true,
 			nextArrow: '<div class="slick-arrow slick-right"><i class="fa fa-chevron-right"></i></div>',
 			prevArrow: '<div class="slick-arrow slick-left"><i class="fa fa-chevron-left"></i></div>',
 			asNavFor: '.productslider-nav'
 		}).show();

 		$('.productslider-nav').slick({
 			slidesToShow: 4,
 			slidesToScroll: 1,
 			asNavFor: '.productslider-for',
 			dots: false,
 			rows: 0,
 			infinite: true,
 			centerMode: false,
 			arrows: false,
 			adaptiveHeight: false,
 			focusOnSelect: true,
 			mobileFirst: true,
 			responsive: [
 			{
 				breakpoint: 1200,
 				settings: {
 					slidesToShow: 4
 				}
 			},
 			{
 				breakpoint: 1023,
 				settings: {
 					slidesToShow: 3
 				}
 			},
 			{
 				breakpoint: 767,
 				settings: {
 					slidesToShow: 2
 				}
 			}]
 		}).show();

			// Play and pause video in product slider
			$('.productslider-for').on('beforeChange', function(event, slick) {
				var currentSlide, slideType, player, command;

				currentSlide = $(slick.$slider).find('.slick-current');
				slideType = currentSlide.attr('class').split(" ")[1];
				player = currentSlide.find('iframe').get(0);

				if (slideType == 'product__video--vimeo') {
					command = {
						'method': 'pause',
						'value': 'true'
					};
				} else {
					command = {
						'event': 'command',
						'func': 'pauseVideo'
					};
				}

				if (player != undefined) {
					player.contentWindow.postMessage(JSON.stringify(command), '*');
				}
			});

		};

		productImage = function() {
			$('.productslider-for').magnificPopup({
				delegate: 'a',
				type: 'image',
				closeOnContentClick: true,
				mainClass: 'mfp-img-mobile',
				gallery: {
					enabled: true,
					navigateByImgClick: true,
					preload: [0,1],
					arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir% mfp-prevent-close"><i class="mfp-prevent-close btn btn--primary fa fa-chevron-%dir%"></i></button>'
				},
				image: {
					verticalFit: true
				}
			});
		};

		rebuildTable = function() {
			if($('.specifications').length !== 0) {
				if($('table.EZ-base').length !== 0) {	
					$('.specifications__removed table.EZ-base tbody').children().each(function(index, tr){
						var $dl = $('<dl>');
						var $dt = $(this).find("td:eq(0)");
						var $dd = $(this).find("td:eq(1)");

						$dl.append($('<dt>').html($dt.html()));  
						$dl.append($('<dd>').html($dd.html()));  
						$dl.appendTo('.specifications');
					});

					$('.specifications').wrapInner('<div class="specifications__table">');
					$('.specifications__removed').remove();
				} else {
					$('.specifications__removed').remove();
					$('.specifications').html('<p>Er zijn geen specificaties beschikbaar.</p>');
				}
			}
		};


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
    	init: function() {

		var $windowWidth = $(window).width();
		$( window ).resize(function() {
			$windowWidth = $(window).width();
			if($windowWidth > 1023) {
				horizontalNavigation();
			} 
		});

        // Navigation and header
        stickyNavigation();
        toggleNavigation();
        toggleSubnav();

        if($windowWidth > 1023) {
        	horizontalNavigation();
        } 

		// Slider homepage
		slideshow();
		blogSlider();

        // Product detail page
        productSlider();
        productImage();
        rebuildTable();
        toggleDiscounts();

        /*----------  On scroll  ----------*/
        $(window).scroll(function() {
        	stickyNavigation();
        });
    },
    finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    }
}
};

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
  	fire: function(func, funcname, args) {
  		var fire;
  		var namespace = Sage;
  		funcname = (funcname === undefined) ? 'init' : funcname;
  		fire = func !== '';
  		fire = fire && namespace[func];
  		fire = fire && typeof namespace[func][funcname] === 'function';

  		if (fire) {
  			namespace[func][funcname](args);
  		}
  	},
  	loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
      	UTIL.fire(classnm);
      	UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
  }
};

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
